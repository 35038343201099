import Nezuko from '../../img/pixelart.webp'
import Symfony from '../../img/symfony_project.png'
import Gta from '../../img/ecrangtavi.png'
import styled from 'styled-components';
const ImgProject = styled.img`
    height:auto;
    width: 250px;
    margin:auto;
`
const FigureProjet = styled.figure`
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    align-items:center;
    height:429px;

`
const ContainerFigure = styled.div`
    align-items: end;
    justify-content: end;
    flex-wrap: wrap;
    gap: 20px;
    margin: auto;
    // width: 100%;
    text-align: center;
    color: white;
    figure img{
        margin: auto;
    }
    figure{
        margin-bottom: 0px;
    }
    figure figcaption {
    margin: auto;
    max-width: 400px;
    border: 5px ridge #FFDD00;
    padding: 20px;
    margin-top: 10px;
    }
`
function Project(){
    return(
        <section id="projects">
            <h2>Projet</h2>
            <div className="lineTitle"></div>
            <h3 className='yellow'>WEB</h3>
            <ContainerFigure>
                <figure>
                    <a href="https://github.com/ealmvin/CinePop.git"><ImgProject src={Symfony} alt="image du projet symfony" /></a>
                    <figcaption>Clique pour ouvrir le lien sur github. Blog de critiques cinématographiques développé avec Symfony : une plateforme dynamique qui permet la gestion et la publication d'analyses de films. Ce projet tire parti de la puissance de Symfony pour offrir une architecture robuste et une gestion efficace des contenus.</figcaption>
                </figure>
            </ContainerFigure>
            <h3 className='yellow'>DESIGN</h3>
            <ContainerFigure>
                <FigureProjet>
                    <ImgProject src={Nezuko} alt="image en pixel art de nezuko" className='nezuko'/>
                    
                    <figcaption>Exploration créative en pixel art : Nezuko, icône de Demon Slayer, redessinée avec une touche rétro, alliant minimalisme et expressivité</figcaption>
                </FigureProjet>
                <FigureProjet>
                    <ImgProject src={Gta} alt="image d'un visuel de GTA" />
                   
                    <figcaption>Concept visuel pour GTA VI : une composition dynamique sur Photoshop, fusionnant graphisme moderne et esthétique du jeu pour un design percutant.</figcaption>
                </FigureProjet>
            </ContainerFigure>

        </section>
    )
}


export default Project;