import styled from "styled-components"
import cv from "../../img/cv_amina_ghoula.pdf"
import imgCv from "../../img/img_cv.png"


const ImgCv = styled.img`
    width:80%;
    max-width:320px;
`
const LinkCv = styled.a`
    text-align:center;
    margin-top:50px;
`
function Cv(){
    return(
        <>
            <section id="cv">
                <h2>Voir mon Cv</h2>
                <div className="lineTitle"></div>
                <LinkCv href={cv} target="_blank" rel="noopener noreferrer"><ImgCv alt="Cv de Amina ghoula" src={imgCv}></ImgCv> </LinkCv>
            </section>
        </>
    )
}

export default Cv