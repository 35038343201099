import { createContext, useEffect, useState } from "react";
import { auth, db } from "../firebase-config";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";


export const MessagesContexte = createContext()

export function MesssagesContexteProvider(props){

    const [ navigates , setNavigates ] = useState(false)
    const [detailUser, setDetailUser] = useState(null)
    const [allUser, setAllUser] = useState([])
    const [userSelect, setUserSelect] = useState([])
    const [loading, setLoading] = useState(true);
    const informationUser = async () => {
        auth.onAuthStateChanged(async (user) => {
            if(user){
                const docRef = doc(db, "Messages", user.uid)
                const captureDoc = await getDoc(docRef)
                if(captureDoc.exists()  ){
                    setDetailUser(captureDoc.data())
                }else{
                    console.log("utilisatuer non logger")
                }
                const querySnapshot = await getDocs(collection(db, "Messages"));
                setAllUser(querySnapshot.docs.map(doc => 
                    doc.data()
                    
                )); 

                setLoading(false); // Terminer le chargement après que les données sont prêtes
            }else {
                setLoading(false); // Terminer le chargement si l'utilisateur n'est pas authentifié
            }
        });
    };
    useEffect(() => {
        informationUser()
    }, [])
    return(
        <>
            {loading ? (
                <div>Chargement...</div>
            ) : (
                <MessagesContexte.Provider value={{ detailUser, allUser, userSelect, setUserSelect, navigates, setNavigates }}>
                    {props.children}
                </MessagesContexte.Provider>
            )}
        
        </>
    )
}