import styled from 'styled-components'
import Politique from '../Politique/Politique'
import { useState } from 'react'
const White = styled.p`
    color: color;
    text-align: center;
`
const FooterA = styled.footer`
    margin-top: 150px;
    margin-bottom: 20px;
`
const PolitiqueTexte = styled.span`
    cursor:pointer;
`
function Footer(){
    const [button, setButton] = useState(false)
    return(
        <>
            <FooterA>
                <White className="black">copyright 2024 © | <PolitiqueTexte onClick={() => setButton(!button)}>politique de confidentialité</PolitiqueTexte></White>
                {button && (
                    <Politique/>
                )}
            </FooterA>
        </>
    )
}


export default Footer;