
import { Route, Routes } from 'react-router-dom';
import './style/style.css'
import Home from './pages/Home/Home';
import Error from './pages/Error/Error';
import NioufHome from './pages/Niouf/NioufHome/NioufHome';
import Niouf from './pages/Niouf/Niouf';
import SignUp from './pages/SignUp/SignUp';
import SignIn from './pages/SignIn/SignIn';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/signupNiouf' element={<SignUp />} />
        <Route path='/loginNiouf' element={<SignIn />} />
        <Route path='/niouf' element={<Niouf />} >
          <Route path='/niouf/nioufhome' element={<NioufHome />} />
        </Route>
        <Route path='*' element={<Error />} />

      </Routes>

    </>

  );
}

export default App;
