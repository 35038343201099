import React from 'react'
import meme from './meme.webp'
import { useNavigate } from 'react-router-dom';
import { auth } from "../../../firebase-config"
import { signOut } from "firebase/auth"

export default function NioufHome() {

  const navigate = useNavigate();
  const logOut = async () => {
      try {
          await signOut(auth)
          navigate("/")
      } catch (error) {
          alert("For some resons we can log you")
          
      }
  }


  return (
    <div className='container p-5'>
      <h1 className='display-3 text-light mb-4'>
        Page privée ma gueule
      </h1>

      <img src={meme} alt='juste un meme'/>
      <button onClick={logOut}>déco</button>
    </div>
  )
}
