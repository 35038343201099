import { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import imgLogo from '../../img/LogoAminaBlanc.webp'
import "./nav.css"
const slider = keyframes`
    to{
       width: 100%;
    }   
`
const Liste = styled.li`
    list-style: none;
    color: #FFFFFF;
    font-size: larger;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    font-weight: 400;
    position: relative;
    &:hover{
        &::after, &::before{
            content: "";
            background: #FFDD00;
            height: 3px;
            width: 0%;
            animation: 0.3s ease-in forwards ${slider};
            position: absolute;
        }
        &::after{
            bottom: 0;
        }
        &::before{
            bottom: 0;
        }

    }
    a{
     text-decoration: none;
     color: white;
    }
`
const ContainerListe = styled.ul`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50px;
    gap: 70px;
`
const Navbar = styled.nav`
    margin-top:20px;
    display:flex;
    align-items:center;
    justify-content: space-around;
`
const LogoAmina = styled.img`
    width:50px;

`

function Nav(){
    // to change burger classes
    const [burger_class, setBurgerClass] = useState("burger-bar unclicked")
    const [menu_class, setMenuClass] = useState("menu hidden")
    const [isMenuClicked, setIsMenuClicked] = useState(false)

    // toggle burger menu change
    const updateMenu = () => {
        if(!isMenuClicked) {
            setBurgerClass("burger-bar clicked")
            setMenuClass("menu visible")
        }
        else {
            setBurgerClass("burger-bar unclicked")
            setMenuClass("menu hidden")
        }
        setIsMenuClicked(!isMenuClicked)
    }

    return(
        <>
            <Navbar>
                <LogoAmina src={imgLogo} alt="Logo portfolio Amina" />

                <div className={menu_class}>
                    <ContainerListe>
                        <Liste><a href="#about">À propos</a></Liste>
                        <Liste><a href="#skills" >Compétences</a></Liste>
                        <Liste><a href="#cv">Mon Cv</a></Liste>
                        <Liste><a href="#projects" >Projets</a></Liste>
                        <Liste><a href="#contact">Contact</a></Liste>
                        
                    </ContainerListe>

                </div>
                <div className="burger-menu" onClick={updateMenu}>
                    <div className={burger_class} ></div>
                    <div className={burger_class} ></div>
                    <div className={burger_class} ></div>
                </div>
            </Navbar>
        </>
    )
}


export default Nav;