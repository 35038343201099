import { useContext, useRef, useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import styled from "styled-components";
import { MessagesContexte } from "../../context/messagesContexte";
import { dataBase } from "../../firebase-config";
import {  push, ref, set } from "firebase/database";
const FormContact = styled.form`
    display:flex;
    flex-direction:column;
    margin-top:79px;
    width:90%;
    height:300px;
    gap:20px;
`;

const ContainerForm = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
`;

const InputText = styled.input`
    height:30px;
    border-radius: 5px;
    padding-left: 5px;
    background:  rgba(244, 247, 78, 0.812);
    &:focus{
        border: solid 5px #FFDD00;
    }
`;

const TextAreaForm = styled.textarea`
    height: 100px;
    border-radius: 5px;
    padding-left: 5px;
    padding-top: 5px;
    background:  rgba(244, 247, 78, 0.812);
`;

const BouttonForm = styled.input`
    width: 35%;
    border: 2px solid #FFDD00;
    background: black;
    color: white;
    margin:auto;
    cursor:pointer;
    height:50px;
`;

function Formulaire() {
    const { detailUser, userSelect, setNavigates } = useContext(MessagesContexte);
    
    const inputNomRef = useRef();
    const inputPrenomRef = useRef();
    const inputEmailRef = useRef();
    const inputMesssageRef = useRef();
    const inputs = useRef([]);
    
    const addInputs = el => {
        if (el && !inputs.current.includes(el)) {
            inputs.current.push(el);
        }
    };
    
    let [error, setError] = useState(false);

    const sendButton = (e) => {
        e.preventDefault();
        setError(false);  // Réinitialisation de l'état d'erreur
        checkInputEmpty(inputNomRef);
        checkInputEmpty(inputPrenomRef);
        checkInputEmpty(inputEmailRef);
        checkInputEmpty(inputMesssageRef);
        
        if (!error) {

            
            
            try {
                const id = Date.now();
                const messageRef = ref(dataBase, 'Messages')
                const newMessageRef = push(messageRef)
                set(newMessageRef, {
                    id: id,
                    nom: inputs.current[0].value,
                    prenom: inputs.current[1].value,
                    mail: inputs.current[2].value,
                    message: inputs.current[3].value
                });

                inputs.current[0].value = ""; // Reset le formulaire après avoir envoyer le message
                inputs.current[1].value = ""; // Reset le formulaire après avoir envoyer le message
                inputs.current[2].value = ""; // Reset le formulaire après avoir envoyer le message
                inputs.current[3].value = ""; // Reset le formulaire après avoir envoyer le message
        
            } catch (error) {
                
                console.log('une erreur est survenue, veuillez vérifier votre connexion internet et actualiser la page');
            }







            
            toast.success("Votre message a bien été envoyé !!", {
                position: "bottom-right"
            });
        }
    };

    function ValidateEmail(input) {
        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return input.value.match(validRegex);
    }

    function checkInputEmpty(inputName) {
        const inputIndex = inputs.current.indexOf(inputName.current);
        
        if (inputName === inputNomRef && inputs.current[0].value.trim() === "") {
            setError(true);
            return toast.warning("Le champ nom est vide !!", { position: "bottom-right" });
        }
        if (inputName === inputPrenomRef && inputs.current[1].value.trim() === "") {
            setError(true);
            return toast.warning("Le champ prénom est vide !!", { position: "bottom-right" });
        }
        if (inputName === inputEmailRef && !ValidateEmail(inputs.current[2])) {
            setError(true);
            return toast.warning("Le champ e-mail présente des fautes !!", { position: "bottom-right" });
        }
        if (inputName === inputMesssageRef && inputs.current[3].value.trim() === "") {
            setError(true);
            return toast.warning("Votre message est vide !!", { position: "bottom-right" });
        }
    }

    useEffect(() => {
        console.log(userSelect)
    }, [userSelect]);

    return (
        <>
            <ContainerForm>
                <FormContact onSubmit={sendButton} action="">
                    <InputText ref={addInputs} type="text" placeholder="nom"></InputText>
                    <InputText ref={addInputs} type="text" placeholder="prenom"></InputText>
                    <InputText ref={addInputs} type="text" placeholder="e-mail"></InputText>
                    <TextAreaForm ref={addInputs} placeholder="Votre message"></TextAreaForm>
                    <BouttonForm type="submit" />
                </FormContact>
            </ContainerForm>
            <ToastContainer />
        </>
    );
}

export default Formulaire;
