import About from "../../components/About/About";
import Contact from "../../components/Contact/Contact";
import Formation from "../../components/Formation/Formation";
import Nav from "../../components/Nav/Nav";
import Presentation from "../../components/Presentation/Presentation";
import Project from "../../components/Project/Project";
import Skills from "../../components/Skills/Skills";
import Footer from "../../components/Footer/Footer";
import Cv from "../../components/Cv/Cv";

function Home (){
    return(
        <>
            <Nav />
            <Presentation />
            <About />
            <Skills />
            <Cv />
            <Project />
            <Contact />
            <Footer />
        
        </>
    )
}

export default Home;