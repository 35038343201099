import Formulaire from "../Formulaire/Formulaire";

function Contact(){
    return(
        <>
            <section id="contact">
                <h2>Contact</h2>
                    <div className="lineTitle"></div>
                        <p>Laissez-moi un message que je puisse vous recontacter !</p>
            </section>
            <Formulaire />
        
        </>
        
    )
}


export default Contact;