function About(){
    return(
        <section>
            <h2>À propos</h2>
            <div className="lineTitle"></div>
            <div className="containerAbout">
                <div className="about">
                    <h3>Mon parcours</h3>
                    <p className="aboutText">
                        Actuellement en deuxième année à Digital Campus, je suis à la recherche d’une alternance en développement web. Je souhaite approfondir mes compétences en travaillant sur des projets concrets, au sein d'une équipe dynamique.
                        J’ai développé une réelle passion pour le design et l’univers du web.
                    </p>

                </div>
                <div className="lineAbout"></div>
                <div className="about">
                    <h3>Mon activité</h3>
                    <p className="aboutText">
                        Depuis 2023, j'ai lancé ma propre activité de vente en ligne de créations personnalisées.
                        je mets à profit ma créativité pour répondre aux besoins de mes clients
                        tout en explorant différentes tendances du marché.
                        J’apprécie la liberté de créer et d'innover dans ce cadre professionnel.
                    </p>
                </div>
            </div>
        </section>
    )
}


export default About;