import React, {useContext} from 'react'
import { userContext } from '../../context/userContext';
import { Navigate, Outlet } from 'react-router-dom';

export default function Niouf() {
  const currentUser  = useContext(userContext)
  console.log("PRIVATE", currentUser);
    //vérifie si currentUser == true / false pour rediriger sur l'accueil 
  if(!currentUser) {
    return <Navigate to="/" />
  }

  return (
    <>
      <div className="container">
        <Outlet />
      </div>
    </>
  )
}