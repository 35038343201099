import styled from "styled-components";
const White = styled.div`
    color: white;
`
function Politique(){
    return(
        <White>
            POLITIQUE DE CONFIDENTIALITÉ
Le but de cette politique est d'informer les utilisateurs de mon site des données personnelles que nous receuillerons. Cette politique de confidentialité fonctionne parrallèlement aux mentions légales de mon site.

Lois applicables
Conformément au Règlement général sur la protection des données (RGPD), cette politique de confidentialité est conforme aux réglements suivants. Les données à caractère personnel doivent être : 1. traitées de manière licite, loyale et transparente au regard de la personne concernée(licéité, loyauté, transparence);

Consentement
Les utilisateurs conviennent qu'en utilisant notre site, ils consentent à : 1. les conditions énoncées dans la présente politique de confidentialité et 2. la collecte, l'utilisation et la conservation des données énumérées dans la présente politique.

Données personnelles que nous collectons
Nous ne collectons aucune donnée automatiquement sur notre site. Nous pouvons également collecter les données suivantes lorsque vous effectuez certaines fonctions sur notre site. 1. Prénom et Nom 2. Email . Ces données peuvent être receuillies au moyen des méthodes suivantes : Formulaire de contact Veuillez noter que nous ne collectons que les données qui nous aident à atteindre l'objectif énoncé dans cette politique de confidentialité. Nous ne recueillerons pas de données supllémentaires sans vous en informer au préalable.

Comment nous utilisons les données personnelles
Les données personnelles recueillies sur notre site seront utilisées uniquement aux fins précisées dans la présente politique ou indiqueés sur les pages pertinentes de notre site. Nous n'utiliserons pas vos données au-delà de ce que nous divulguerons. Les données que nous recueillons lorsque l'utilisateur exécute certaines fonctions peuvent être utilisées à des fins de communication.

Avec qui nous partageons les données personnelles
Les données sont partagés uniquement avec l'éditeur du site. Nous nous engageons à ne pas vendre ou partager vos données avec des tiers, sauf dans les cas suivants: Si la loi l'exige, Si elle est requise pour toute procédure judiciaire, Pour prouver ou protéger nos droits légaux, À des acheteurs ou des acheteurs potentiels de cette société dans le cas où nous cherchons à la vendre la société. Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous ne sommes pas responsables et n'avons pas de contrôle sur leurs politiques et pratiques de confidentialité.

Combien de temps nous stockons les données personnelles
Nous ne conservons pas les données des utilisateurs au-delà de ce qui est nécessaire pour atteindre les fins pour lesquelles elles sont recueillies.

Vos droits en tant qu'utilisateur
En vertu du RGPD, les utilisateurs ont les droits suivants en tant que personnes concernées : 1. Droit d'accès 2. Droit de rectification 3. Droit à l'effacement 4. Droit de restreindre le traitement 5. Droit à la portabilité des données 6. Droit d'objection. Vous trouverez de plus amples informations sur ces droits au chapitre 3 (art 12-23) du RGPD.

Comment modifier, supprimer ou contester les données recueillies
Si vous souhaitez que vos renseignements soient supprimés ou modifiés d'une façon ou d'une autre, veuillez communiquer avec notre agent de protection de la vie privée ici : Amina Ghoula, 13 Rue de lugoj 45100 Orléans, ghoulamina45@gmail.com, 0651260316

ACCUEIL COMPÉTENCES CONTACT
MENTIONS LÉGALES POLITIQUE DE CONFIDENTIALITÉ
        </White>
    )
}


export default Politique;