import React, { useContext, useRef, useState } from 'react'
import { userContext} from '../../context/userContext'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
const ContainerSignUp = styled.div`
`

const FormSignUp = styled.form`
    display:flex;
    flex-direction:column;
    width: 450px;
    border: 2px solid #FFDD00;
    gap: 10px;
    justify-content: center;
`

export default function SignInModul() {

  const [validation, setValidation] = useState('')
  const inputs = useRef([])

  const addInput = el => {
    if(el && !inputs.current.includes(el)){
      inputs.current.push(el)
    }
  }
  const { signIn } = useContext(userContext)
  const navigate = useNavigate();

  const formRef = useRef();

  const handleForm = async (e) => {
    e.preventDefault(inputs)

    try{

      const cred = await signIn(
        inputs.current[0].value,
        inputs.current[1].value
      )
      formRef.current.reset();
      setValidation("");
      navigate("../niouf/nioufhome")
      //dans le cas où il n'y a pas d'erreur -> redirection
      // toggleModals("close")

    }catch {
    }
    setValidation("Oupsi, email or password invalid")
  }
   return (
    <>
    {
      <div className=''>
        <div
        className=''>
            <ContainerSignUp>
              <div>
                  <h5 className=''>Sign up</h5>
                  <button
                  className=''></button>
              </div>

              <div className='modal-body'>
                <FormSignUp 
                onSubmit={handleForm}
                className='sign-up-form'
                ref={formRef}
                >
                  <label htmlFor='signInpEmail' className='form-label'>Email Adress</label>
                  <input
                  ref={addInput}
                  name="email"
                  required
                  type='email' 
                  className='form-control'
                  id='signInEmail'
                  />

                  <label htmlFor='signInPwd' className='form-label'>Password user</label>
                  <input
                  ref={addInput}
                  name='pwd'
                  required
                  className='form-control'
                  type="password"
                  id='signInPwd'
                  />
                  <p className=''>{validation}</p>

                  <button className=''>Submit</button>
                </FormSignUp>
              </div>
            </ContainerSignUp>
        </div>
      </div>
    }
    </>
    
    
  )
}
                
                    
                    
                
